import { useEffect, useState, memo } from "react";

import { Container, Row, Col, Card, Form } from "react-bootstrap";

import Meta from "../components/Meta";

import { useReadContract, usePublicClient } from "wagmi";
import useNumberAnimation from "../hooks/useNumberAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faSpinner, faStore } from "@fortawesome/free-solid-svg-icons";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import abi from "../contracts/ABI.json";

import { mainnet } from "viem/chains";

const NFT_NAMES = JSON.parse(process.env.REACT_APP_NFT_NAMES || "[]");
const NFT_IMAGES = JSON.parse(process.env.REACT_APP_NFT_IMAGES || "[]");
const VERSION = process.env.REACT_APP_VERSION;
const NODEJS_SERVER_URL = process.env.REACT_APP_NODEJS_LINK;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const PROJECT_BTC_ADDRESS = process.env.REACT_APP_PROJECT_BTC_ADDRESS;

const Dashboard = () => {
  const pageTitle = "Marmottoshis - Dashboard";

  const [pastRedeems, setPastRedeems] = useState([]);
  const [pastSatoshis, setPastSatoshis] = useState([]);
  const [pastSatoshisCount, setPastSatoshisCount] = useState(0);

  const [nfts, setNFTs] = useState([]);
  const [sortBy, setSortBy] = useState("id");

  const publicClient = usePublicClient({ chainId: 1 });

  const { data: totalSupply } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: abi,
    functionName: "totalSupply",
    chainId: mainnet.id,
  });

  const animatedSupply = useNumberAnimation(totalSupply, {
    reverse: true,
    maxValue: 777,
    duration: 1000,
  });

  const animatedBurnsCount = useNumberAnimation(pastRedeems?.length || 0, {
    duration: 1000,
  });
  const animatedRedistributionsCount = useNumberAnimation(pastSatoshisCount, {
    duration: 1500,
  });

  const getPastRedeems = async () => {
    try {
      const response = await fetch(`${NODEJS_SERVER_URL}/redeems`);
      if (!response.ok) throw new Error(response.status);

      const past_redeems = await response.json();
      if (!past_redeems || typeof past_redeems !== "object") {
        throw new Error("Invalid redeems data");
      }

      const past_redeems_array = Object.keys(past_redeems).map((key) => {
        const args = past_redeems[key]?.args || [];
        const nft = args[1]?.toString() || "";

        return {
          tx: past_redeems[key]?.transactionHash || "",
          from: args[0] || "",
          nft: nft,
          nft_name: NFT_NAMES[parseInt(nft) - 1] || "",
          sats: args[4] || 0,
          btc_address: args[3] || "",
          timestamp: past_redeems[key]?.timestamp || 0,
        };
      });

      setPastRedeems(past_redeems_array.reverse());
    } catch (error) {
      console.error("Error fetching redeems data: ", error);
      setPastRedeems([]);
    }
  };

  const getPastSatoshisAdd = async () => {
    try {
      const response = await fetch(`${NODEJS_SERVER_URL}/satoshis`);
      if (!response.ok) throw new Error(response.status);
      const jsonData = await response.json();
      if (!jsonData?.transactions) {
        throw new Error("Invalid satoshis data");
      }
      const quarterData = marmottoshisLoveQuarters(jsonData);
      setPastSatoshis(quarterData);
      setPastSatoshisCount(jsonData.transactions.length);
    } catch (error) {
      console.error("Error fetching satoshis data: ", error);
      setPastSatoshis([]);
      setPastSatoshisCount(0);
    }
  };

  const marmottoshisLoveQuarters = (jsonData) => {
    if (!jsonData?.transactions?.length) {
      return [];
    }

    const getQuarter = (month) => {
      const quarters = [
        "Q1",
        "Q1",
        "Q1",
        "Q2",
        "Q2",
        "Q2",
        "Q3",
        "Q3",
        "Q3",
        "Q4",
        "Q4",
        "Q4",
      ];
      return quarters[month - 1] || "Q1";
    };

    const formatDate = (dateStr) => {
      const [day, month] = dateStr.split("/");
      const monthNames = [
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sep",
        "Oct",
        "Nov",
        "Déc",
      ];
      return `${day} ${monthNames[parseInt(month) - 1] || "Jan"}`;
    };

    const getCurrentQuarter = () => {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      return `${getQuarter(currentMonth)} ${currentYear}`;
    };

    const currentQuarter = getCurrentQuarter();
    let transactionsByQuarter = {};
    let currentQuarterTransactions = [];

    jsonData.transactions.forEach((transaction) => {
      if (!transaction?.date) return;

      const [day, month, year] = transaction.date.split("/").map(Number);
      const quarter = `${getQuarter(month)} ${year}`;

      if (quarter === currentQuarter) {
        const formattedTransaction = {
          ...transaction,
          date: formatDate(transaction.date),
        };
        currentQuarterTransactions.push(formattedTransaction);
      } else {
        if (!transactionsByQuarter[quarter]) {
          transactionsByQuarter[quarter] = {
            date: quarter,
            Satoshis: 0,
            "Cumul en ₿": 0,
          };
        }

        transactionsByQuarter[quarter].Satoshis += transaction.Satoshis || 0;
        transactionsByQuarter[quarter]["Cumul en ₿"] =
          transaction["Cumul en ₿"] || 0;
      }
    });

    const mergedTransactions = Object.values(transactionsByQuarter).concat(
      currentQuarterTransactions
    );

    return mergedTransactions;
  };

  const Populate = async () => {
    try {
      const nfts = [];

      const promises = Array.from({ length: 21 }, async (_, id) => {
        try {
          const supply = await publicClient.readContract({
            address: CONTRACT_ADDRESS,
            abi: abi,
            functionName: "supplyByID",
            args: [id + 1],
          });

          const nft = { id: id + 1, supply: parseInt(supply) || 0 };

          if (supply >= 1) {
            const sats = await publicClient.readContract({
              address: CONTRACT_ADDRESS,
              abi: abi,
              functionName: "redeemableById",
              args: [id + 1],
            });
            nft.sats = parseInt(sats) || 0;
          } else {
            nft.sats = 0;
          }

          return nft;
        } catch (error) {
          console.error(`Error fetching NFT ${id + 1}:`, error);
          return { id: id + 1, supply: 0, sats: 0 };
        }
      });

      const results = await Promise.all(promises);
      nfts.push(...results);

      setNFTs(nfts);
      getPastSatoshisAdd();
    } catch (error) {
      console.error("Error in Populate:", error);
      setNFTs([]);
    }
  };

  const SingleNFT = memo(({ nft_family_id, nftSupply, nftSats }) => {
    const nft_index = nft_family_id - 1;
    return (
      <Col sm={6} md={4}>
        <Card className="mb-4">
          {nft_index === 1 ? (
            <video
              autoPlay
              muted
              loop
              poster={`/images/nfts/${NFT_IMAGES[nft_index]}.jpg?v=${VERSION}`}
            >
              <source
                src={`/images/nfts/${NFT_IMAGES[nft_index]}.mp4?v=${VERSION}`}
                type="video/mp4"
              />
            </video>
          ) : (
            <Card.Img
              variant="top"
              src={`/images/nfts/${NFT_IMAGES[nft_index]}?v=${VERSION}`}
            />
          )}
          <Card.Body>
            <Card.Title>{NFT_NAMES[nft_index]}</Card.Title>
            {nftSupply >= 1 ? (
              <a
                href={`${process.env.REACT_APP_MP_LINK}:${CONTRACT_ADDRESS}:${nft_family_id}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-dark btn-lg"
                title="Voir sur la marketplace"
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className="flex-shrink-0 me-3"
                  color="brown"
                  size="lg"
                />
                Marketplace
              </a>
            ) : (
              <a
                href="#"
                className="btn btn-dark btn-lg disabled"
                title="Plus disponible"
              >
                <FontAwesomeIcon
                  icon={faStore}
                  className="flex-shrink-0 me-3"
                  color="brown"
                  size="lg"
                />
                Marketplace
              </a>
            )}
          </Card.Body>
          <Card.Footer>
            {nftSupply >= 1 ? (
              <>
                {nftSupply}{" "}
                {nftSupply >= 2 ? <>exemplaires</> : <>exemplaire</>}
              </>
            ) : (
              <>Plus</>
            )}{" "}
            en circulation
            <br />
            {nftSats >= 1 ? <>{nftSats} Satoshis adossés</> : null}
            <br />
          </Card.Footer>
        </Card>
      </Col>
    );
  });

  const ListItem = memo(({ redeem }) => (
    <li>
      <FontAwesomeIcon icon={faFire} color="brown" size="lg" fixedWidth fade />
      <a href={`https://etherscan.io/tx/${redeem.tx}`} target="_blank">
        {new Date(redeem.timestamp * 1000).toLocaleDateString("fr-FR")}
      </a>{" "}
      &bull; Burn d'une tirelire <strong>{redeem.nft_name}</strong> et obtention
      de <strong>{redeem.sats}</strong> Satoshis sur{" "}
      <a
        href={`https://www.blockchain.com/btc/address/${redeem.btc_address}`}
        target="_blank"
      >
        {redeem.btc_address === PROJECT_BTC_ADDRESS
          ? "la tirelire du projet (merci 🙏)"
          : "ce wallet"}
      </a>
      .
    </li>
  ));

  // Effects
  useEffect(() => {
    getPastRedeems();
    Populate();
  }, []);

  const m_dashboardUI = () => {
    const sortedNFTs = [...(nfts || [])].sort((a, b) => {
      if (sortBy === "nftSupply") return (a.supply || 0) - (b.supply || 0);
      if (sortBy === "nftSats") return (b.sats || 0) - (a.sats || 0);
      return (a.id || 0) - (b.id || 0); // Default sort by ID
    });

    const listItems = sortedNFTs.map((nft) => (
      <SingleNFT
        key={nft.id}
        nft_family_id={nft.id}
        nftSupply={nft.supply}
        nftSats={nft.sats}
      />
    ));

    return (
      <>
        <h4 className="mb-4">Supply : {animatedSupply}/777</h4>
        <Form.Group className="mb-5 d-flex justify-content-center">
          <Form.Label className="me-2 mt-2" style={{ fontSize: "0.9rem" }}>
            Trier par :
          </Form.Label>
          <Form.Select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="w-auto"
            style={{
              color: "#4c2b18",
              border: "1px solid black",
              fontSize: "0.9rem",
            }}
          >
            <option value="id">ID</option>
            <option value="nftSupply">Exemplaires</option>
            <option value="nftSats">Satoshis adossés</option>
          </Form.Select>
        </Form.Group>
        {nfts.length < 21 ? (
          <>
            <FontAwesomeIcon
              icon={faSpinner}
              color="brown"
              size="lg"
              fixedWidth
              spin
              fade
            />
            <br />
            Chargement des données On-Chain en cours, patience...
          </>
        ) : (
          <Row className="nfts-cards">{listItems}</Row>
        )}
      </>
    );
  };

  const BurnsUI = memo(({ pastRedeems, burnsCount }) => (
    <>
      <h4 className="mb-4">{burnsCount > 0 && burnsCount} Burns</h4>
      {pastRedeems?.length >= 1 ? (
        <ul className="redeems m-0 p-0 text-start">
          {pastRedeems.map((redeem, index) => (
            <ListItem key={index} redeem={redeem} />
          ))}
        </ul>
      ) : (
        <>Chargement en cours, patience...</>
      )}
    </>
  ));

  const SatoChart = memo(({ data }) => {
    if (!data || data.length <= 1) {
      return "Chargement en cours, patience...";
    }

    return (
      <>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 50,
              left: 50,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" stroke="#FFF" />
            <YAxis yAxisId="left" orientation="left" stroke="#d9bc3c" />
            <YAxis yAxisId="right" orientation="right" stroke="#e49a72" />
            <Tooltip
              contentStyle={{
                backgroundColor: "#4c2b18",
                border: "none",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
              cursor={false}
            />
            <Legend />
            <Bar dataKey="Satoshis" yAxisId="left" fill="#d9bc3c" />
            <Bar dataKey="Cumul en ₿" yAxisId="right" fill="#e49a72" />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  });

  const dashboardUI = () => {
    return (
      <>
        <h1 className="text-center my-4 page-title">Dashboard</h1>
        <Row>
          <Col sm={12} className="relief mb-5 p-4">
            <BurnsUI
              pastRedeems={pastRedeems}
              burnsCount={animatedBurnsCount}
            />
          </Col>
          <Col sm={12} className="relief mb-5 p-4">
            <h4 className="mb-4">
              {animatedRedistributionsCount > 0 && animatedRedistributionsCount}{" "}
              Redistributions de Satoshis
            </h4>
            {animatedRedistributionsCount === pastSatoshisCount ? (
              <SatoChart data={pastSatoshis} />
            ) : (
              <div>Chargement en cours, patience...</div>
            )}
          </Col>
          <Col sm={12} className="relief p-4">
            {m_dashboardUI()}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Meta title={pageTitle} />

      <Container>
        <Row>
          <Col sm={12} className="mt-4">
            {dashboardUI()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
