import { useState, useEffect } from "react";

const useNumberAnimation = (endValue, options = {}) => {
  const {
    duration = 2000,
    reverse = false,
    startValue = reverse ? options.maxValue || 0 : 0,
  } = options;
  const [displayedValue, setDisplayedValue] = useState(startValue);

  useEffect(() => {
    if (endValue !== undefined) {
      const start = startValue;
      const end = parseInt(endValue);
      const startTime = Date.now();

      const updateNumber = () => {
        const now = Date.now();
        const progress = Math.min((now - startTime) / duration, 1);

        const easeOutQuad = (t) => t * (2 - t);
        const easedProgress = easeOutQuad(progress);

        const current = Math.round(start + (end - start) * easedProgress);
        setDisplayedValue(current);

        if (progress < 1) {
          requestAnimationFrame(updateNumber);
        }
      };

      requestAnimationFrame(updateNumber);
    }
  }, [endValue, duration, startValue]);

  return displayedValue;
};

export default useNumberAnimation;
